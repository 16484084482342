*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  background-color: #F7F9FE;
  min-height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;

  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}
/*   ------ Arrow for ProfileMenu component ------    */
.triangle-with-shadow {
  width: 66px;
  height: 75px;
  position: absolute;
  overflow: hidden;
  background-color: transparent;
  top: -15px;
  right: 20px;
  z-index: 1303 !important;
}
.triangle-with-shadow:after {
  content: '';
  position: absolute;
  width: 48px;
  height: 51px;
  background: #ffffff;
  transform: rotate(45deg);
  top: 75px;
  left: 25px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  z-index: 1301 !important;
}
.triangle-with-shadow:hover,
.triangle-with-shadow:hover:after {
  box-shadow: none;
}

/*  Input autofill background color - Clean user-agent instruction */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: gray #ddd;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 3px solid #ddd;
}

/* Overwrite Material KeyboardDatePicker */
.MuiPickersToolbar-toolbar {
  height: unset !important;
}
.MuiPickersCalendarHeader-dayLabel {
  text-transform: uppercase;
}

/* Overwrite Material MuiOutlinedInput on focus
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline is buggy, so we have to override the standard behavior  
Maybe fixed in next Material version */
.MuiInput-underline:after, .MuiInput-underline:before {
  display: none !important;
}

/* Overwrite notistack-SnackbarContainer fontFamily */
.notistack-SnackbarContainer {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
